import React from "react";

const ScrollMobileIcon = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        opacity="0.5"
        cx="24"
        cy="24"
        r="16.75"
        fill="#FCFCFC"
        stroke="url(#paint0_linear_335_27)"
        stroke-width="0.5"
      />
      <circle
        opacity="0.15"
        cx="24"
        cy="24"
        r="23.75"
        fill="#FCFCFC"
        stroke="url(#paint1_linear_335_27)"
        stroke-width="0.5"
      />
      <circle
        cx="24"
        cy="24"
        r="8.5"
        fill="#FCFCFC"
        stroke="url(#paint2_linear_335_27)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_335_27"
          x1="7"
          y1="7"
          x2="37.0831"
          y2="7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7DC5" />
          <stop offset="1" stop-color="#1E5F6C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_335_27"
          x1="0"
          y1="0"
          x2="42.4703"
          y2="-8.81236e-09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7DC5" />
          <stop offset="1" stop-color="#1E5F6C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_335_27"
          x1="15"
          y1="15"
          x2="30.9264"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7DC5" />
          <stop offset="1" stop-color="#1E5F6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ScrollMobileIcon;
