import React from "react";
import { OBJECTIVES } from "../../constants";
import "./objectives.scss";

const Objectives = () => {
  return (
    <section id="objectives" className="objectives container">
      <h2 className="objectives-title">Objectives:</h2>
      <div className="objectives-items">
        {OBJECTIVES.map((el) => (
          <div key={el} className="objectives-item">
            <div className="objectives-item__icon">{el.image}</div>
            <h5 className="objectives-item__title">{el.title}</h5>
            <p className="objectives-item__text">{el.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Objectives;
