import React from "react";

const ExplorationIcon = () => {
  return (
    <svg
      width="105"
      height="105"
      viewBox="0 0 105 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="104"
        height="104"
        rx="9.5"
        stroke="url(#paint0_linear_303_32)"
      />
      <g clip-path="url(#clip0_303_32)">
        <path
          d="M28.761 43.6419H66.6926C70.4207 43.6419 73.4536 40.609 73.4536 36.881C73.4536 33.1529 70.4207 30.12 66.6926 30.12H28.761C25.0331 30.12 22 33.1529 22 36.881C22 40.609 25.0331 43.6419 28.761 43.6419ZM63.7073 34.6419C64.9418 34.6419 65.9463 35.6464 65.9463 36.881C65.9463 38.1155 64.9418 39.12 63.7073 39.12C62.4727 39.12 61.4683 38.1155 61.4683 36.881C61.4683 35.6464 62.4727 34.6419 63.7073 34.6419ZM30.1658 35.1249H36.3122C37.2805 35.1249 38.0683 35.9125 38.0683 36.881C38.0683 37.8493 37.2805 38.6371 36.3122 38.6371H30.1658C29.1975 38.6371 28.4098 37.8493 28.4098 36.881C28.4098 35.9127 29.1975 35.1249 30.1658 35.1249Z"
          fill="#383838"
        />
        <path
          d="M28.7611 59.4467H53.9748C54.0981 52.9941 59.3818 47.7821 65.8634 47.7821C68.517 47.7821 70.9701 48.6561 72.9508 50.1308C71.9409 47.666 69.5168 45.9248 66.6926 45.9248H28.761C25.0331 45.9248 22 48.9576 22 52.6858C22.0002 56.4138 25.0333 59.4467 28.7611 59.4467ZM30.166 50.9297H36.3124C37.2807 50.9297 38.0685 51.7173 38.0685 52.6858C38.0685 53.6541 37.2807 54.4419 36.3124 54.4419H30.166C29.1977 54.4419 28.4099 53.6541 28.4099 52.6858C28.4099 51.7175 29.1977 50.9297 30.166 50.9297Z"
          fill="#383838"
        />
        <path
          d="M70.8664 70.4633C69.289 71.1983 67.6058 71.5709 65.8634 71.5709C60.0053 71.5709 55.1259 67.3127 54.1499 61.7297H28.761C25.0331 61.7297 22 64.7625 22 68.4907C22 72.2187 25.0331 75.2517 28.761 75.2517H66.6926C69.3333 75.2517 71.6238 73.729 72.7361 71.5165L71.4236 70.204L70.8664 70.4633ZM36.3122 70.2466H30.1658C29.1975 70.2466 28.4097 69.4588 28.4097 68.4905C28.4097 67.522 29.1975 66.7344 30.1658 66.7344H36.3122C37.2805 66.7344 38.0683 67.522 38.0683 68.4905C38.0683 69.4588 37.2805 70.2466 36.3122 70.2466Z"
          fill="#383838"
        />
        <path
          d="M81.4782 72.8742L73.4244 64.9193C74.4529 63.4275 75.057 61.6215 75.057 59.6765C75.057 54.5704 70.903 50.4164 65.7971 50.4164C60.6909 50.4164 56.5369 54.5704 56.5369 59.6765C56.5369 64.7826 60.6911 68.9367 65.7971 68.9367C67.6911 68.9367 69.4535 68.364 70.9221 67.3843L79.0102 75.373C79.3524 75.711 79.7983 75.8796 80.2442 75.8796C80.6972 75.8796 81.1501 75.7054 81.4936 75.3575C82.1752 74.6677 82.1683 73.5558 81.4782 72.8742ZM59.475 59.6765C59.475 56.1904 62.3111 53.3545 65.7971 53.3545C69.283 53.3545 72.1189 56.1904 72.1189 59.6765C72.1189 63.1627 69.283 65.9986 65.7971 65.9986C62.3111 65.9986 59.475 63.1627 59.475 59.6765Z"
          fill="#383838"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_303_32"
          x1="0"
          y1="0"
          x2="92.9038"
          y2="-1.9277e-08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7DC5" />
          <stop offset="1" stop-color="#1E5F6C" />
        </linearGradient>
        <clipPath id="clip0_303_32">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(22 23)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExplorationIcon;
