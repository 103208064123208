import React from "react";

const ProductivityIcon = () => {
  return (
    <svg
      width="105"
      height="105"
      viewBox="0 0 105 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="104"
        height="104"
        rx="9.5"
        stroke="url(#paint0_linear_303_30)"
      />
      <path
        d="M51 29H71M51 37H61"
        stroke="#383838"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41 29H37C35.8954 29 35 29.8954 35 31V35C35 36.1046 35.8954 37 37 37H41C42.1046 37 43 36.1046 43 35V31C43 29.8954 42.1046 29 41 29Z"
        fill="#383838"
        stroke="#383838"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51 49H71M51 57H61"
        stroke="#383838"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41 49H37C35.8954 49 35 49.8954 35 51V55C35 56.1046 35.8954 57 37 57H41C42.1046 57 43 56.1046 43 55V51C43 49.8954 42.1046 49 41 49Z"
        fill="#383838"
        stroke="#383838"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51 69H71M51 77H61"
        stroke="#383838"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41 69H37C35.8954 69 35 69.8954 35 71V75C35 76.1046 35.8954 77 37 77H41C42.1046 77 43 76.1046 43 75V71C43 69.8954 42.1046 69 41 69Z"
        fill="#383838"
        stroke="#383838"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.4387 35.875L42.8714 31.4415L41.7632 30.3334L38.4387 33.6587L36.7749 31.9956L35.6667 33.1038L38.4387 35.875Z"
        fill="white"
      />
      <path
        d="M38.4387 55.875L42.8714 51.4415L41.7632 50.3334L38.4387 53.6587L36.7749 51.9956L35.6667 53.1038L38.4387 55.875Z"
        fill="white"
      />
      <path
        d="M38.4387 75.875L42.8714 71.4415L41.7632 70.3334L38.4387 73.6587L36.7749 71.9956L35.6667 73.1038L38.4387 75.875Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_303_30"
          x1="0"
          y1="0"
          x2="92.9038"
          y2="-1.9277e-08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7DC5" />
          <stop offset="1" stop-color="#1E5F6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProductivityIcon;
