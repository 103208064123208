import ExplorationIcon from "../assets/icons/ExplorationIcon";
import ImproveIcon from "../assets/icons/ImproveIcon";
import NetworkingIcon from "../assets/icons/NetworkingIcon";
import ProductivityIcon from "../assets/icons/ProductivityIcon";

import IvanLogo from "../assets/images/ivan-m.png";
import instIcon from "../assets/icons/inst.svg";
import bulatSocialIcon from "../assets/icons/kontur-balt.svg";
import BrowserIcon from "../assets/icons/BrowserIcon";

import projectOneImage from "../assets/images/kcd-project.webp";
import projectTwoImage from "../assets/images/privalov-news.webp";
import projectThreeImage from "../assets/images/vr-agency.webp";
import projectFourImage from "../assets/images/floppy-store.jpeg";
import YoutubeIcon from "../assets/icons/YoutubeIcon";
import ZenIcon from "../assets/icons/ZenIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import FileMarketLogoIcon from "../assets/icons/FileMarketLogoIcon";
import TelegramIcon from "../assets/icons/TelegramIcon";

export const MENU_LINKS = [
  {
    id: 1,
    text: "Current project",
    id: "#currentProject",
  },
  {
    id: 2,
    text: "Recent project",
    id: "#projects",
  },
  {
    id: 3,
    text: "Objectives",
    id: "#objectives",
  },
  {
    id: 4,
    text: "Partnership",
    id: "#partners",
  },
];

export const CURRENT_PROJECT = {
  googlePlayLink: "https://play.google.com/store/apps/details?id=app.words27&pcampaignid=web_share",
  telegramLink: "https://t.me/app27words",
  browserLink: "https://ubc.one/27words.pdf",
  appStoreLink: "https://apps.apple.com/kz/app/27words/id6446810113",
};

export const PROJECTS_COLUMN_ONE = [
  {
    id: 1,
    title: "“Know-Can-Do”",
    texts: [
      "Vocational guidance project for students and schoolchildren. A project to create an environment for communication and transfer of experiences between St. Petersburg university graduates and students. One of the first projects implemented. After a series of educational meetings, the project was suspended.",
      "Five years later, as part of the city's youth policy program, the project was relaunched. A career guidance YouTube channel was created for schoolchildren with short clips-interviews with representatives of interesting professions.",
    ],
    image: projectOneImage,
    link: "https://www.youtube.com/channel/UCN99DHPC3onpvE4hPF1GJdA",
    icon: <YoutubeIcon />,
    time: {
      firstPart: "October, 2015 - October, 2016",
      secondPart: "February, 2021 - August, 2021",
    },
    isComingSoon: false,
  },
  {
    id: 2,
    title: "VISUAL.SOLUTIONS.AGENCY",
    texts: [
      "A project that brings together designers, programmers, artists, and simply creative individuals for the purpose of bringing images, ideas, and meanings into color and form. A design and marketing project to help promote and create a corporate identity for youth and startup projects. Now an internal UBC Inc. design project.",
    ],
    image: projectThreeImage,
    link: "https://www.instagram.com/visual.solutions.agency/",
    icon: <InstagramIcon />,
    time: {
      firstPart: "October, 2020 - now",
    },
    isComingSoon: false,
  },
  {
    id: 3,
    title: "NearMe TON Mini App",
    texts: [
      "The UBC Inc. team are working on the TON Mini App. We want to realize a complete solution for event organizers, as well as for everyone who cares about the life of the night city.",
    ],
    image: null,
    link: "https://t.me/NearMe_events_bot",
    icon: <TelegramIcon />,
    time: {
      firstPart: "June, 2023 - now",
    },
    isComingSoon: true,
  },
];

export const PROJECTS_COLUMN_SECOND = [
  {
    id: 1,
    title: "Privalov News",
    texts: [
      "Originally a project from the field of journalism. An Internet publication about social and economic events. The project brought together students interested in different areas of society and willing to share their thoughts. After three unsuccessful attempts to obtain a publishing license, the project was transformed into a blog about cars on the Yandex platform",
    ],
    image: projectTwoImage,
    link: "https://dzen.ru/id/5dec2deb43fdc000ae534e8b",
    icon: <ZenIcon />,
    time: {
      firstPart: "January, 2018 - May, 2021",
    },
    isComingSoon: false,
  },
  {
    id: 2,
    title: "Floppy.store",
    texts: [
      "The UBC Inc. team plans to develop a MVP version of cloud port. The main purpose of the app is to make it easier to work with cloud storage when transferring and storing multiple files.",
    ],
    image: projectFourImage,
    link: "https://floppy.store/",
    icon: <BrowserIcon />,
    time: {
      firstPart: "March, 2023 - now",
    },
    isComingSoon: false,
  },
  {
    id: 3,
    title: "PiggyHODL",
    texts: [
      "The UBC Inc. team plans to develop a Cryptocurrency Application. The conditions of the new times dictate new rules for money management. We want to help users and make the world of cryptocurrency more convenient and familiar for them",
    ],
    image: null,
    link: "https://piggyhodl.xyz/",
    icon: <BrowserIcon />,
    time: {
      firstPart: "April, 2024 - now",
    },
    isComingSoon: true,
  },
];

export const PROJECTS_MOBILE = [
  {
    id: 1,
    title: "“Know-Can-Do”",
    texts: [
      "Vocational guidance project for students and schoolchildren. A project to create an environment for communication and transfer of experiences between St. Petersburg university graduates and students. One of the first projects implemented. After a series of educational meetings, the project was suspended.",
      "Five years later, as part of the city's youth policy program, the project was relaunched. A career guidance YouTube channel was created for schoolchildren with short clips-interviews with representatives of interesting professions.",
    ],
    image: projectOneImage,
    link: "https://www.youtube.com/channel/UCN99DHPC3onpvE4hPF1GJdA",
    icon: <YoutubeIcon />,
    time: {
      firstPart: "October, 2015 - October, 2016",
      secondPart: "February, 2021 - August, 2021",
    },
    isComingSoon: false,
  },
  {
    id: 2,
    title: "Privalov News",
    texts: [
      "Originally a project from the field of journalism. An Internet publication about social and economic events. The project brought together students interested in different areas of society and willing to share their thoughts. After three unsuccessful attempts to obtain a publishing license, the project was transformed into a blog about cars on the Yandex platform",
    ],
    image: projectTwoImage,
    link: "https://dzen.ru/id/5dec2deb43fdc000ae534e8b",
    icon: <ZenIcon />,
    time: {
      firstPart: "January, 2018 - May, 2021",
    },
    isComingSoon: false,
  },
  {
    id: 3,
    title: "VISUAL.SOLUTIONS.AGENCY",
    texts: [
      "A project that brings together designers, programmers, artists, and simply creative individuals for the purpose of bringing images, ideas, and meanings into color and form. A design and marketing project to help promote and create a corporate identity for youth and startup projects. Now an internal UBC Inc. design project.",
    ],
    image: projectThreeImage,
    link: "https://www.instagram.com/visual.solutions.agency/",
    icon: <InstagramIcon />,
    time: {
      firstPart: "October, 2020 - now",
    },
    isComingSoon: false,
  },
  {
    id: 4,
    title: "Floppy.store",
    texts: [
      "The UBC Inc. team plans to develop a MVP version of cloud port. The main purpose of the app is to make it easier to work with cloud storage when transferring and storing multiple files.",
    ],
    image: projectFourImage,
    link: "https://floppy.store/",
    icon: <BrowserIcon />,
    time: {
      firstPart: "March, 2023 - now",
    },
    isComingSoon: false,
  },
  {
    id: 5,
    title: "NearMe TON Mini App",
    texts: [
      "The UBC Inc. team are working on the TON Mini App. We want to realize a complete solution for event organizers, as well as for everyone who cares about the life of the night city.",
    ],
    image: null,
    link: "https://t.me/NearMe_events_bot",
    icon: <TelegramIcon />,
    time: {
      firstPart: "June, 2023 - now",
    },
    isComingSoon: true,
  },
  {
    id: 6,
    title: "PiggyHODL",
    texts: [
      "The UBC Inc. team plans to develop a Cryptocurrency Application. The conditions of the new times dictate new rules for money management. We want to help users and make the world of cryptocurrency more convenient and familiar for them",
    ],
    image: null,
    link: "https://piggyhodl.xyz/",
    icon: <BrowserIcon />,
    time: {
      firstPart: "April, 2024 - now",
    },
    isComingSoon: true,
  },
];

export const OBJECTIVES = [
  {
    id: 1,
    title: "Networking",
    text: "Developing networking between the association's partners",
    image: <NetworkingIcon />,
  },
  {
    id: 2,
    title: "Productivity",
    text: "Improving economic and resource efficiency",
    image: <ProductivityIcon />,
  },
  {
    id: 3,
    title: "Improve",
    text: "Seeking new ideas and developing existing projects;",
    image: <ImproveIcon />,
  },
  {
    id: 4,
    title: "Exploration",
    text: "Creating products and promoting new values",
    image: <ExplorationIcon />,
  },
];

export const ROADMAP = [
  {
    id: 1,
    period: "March, 2020",
    text: "The idea of creating app to help people learn foreign words was born",
  },
  {
    id: 2,
    period: "September, 2020",
    text: "Release the first prototype of the 27words App in GooglePlay",
  },
  {
    id: 3,
    period: "July, 2023",
    text: "27words in modern design with new games in App Store",
  },
  {
    id: 4,
    period: "September, 2024",
    text: "Web version of the app and sharing dictionary",
  },
  {
    id: 5,
    period: "December, 2023",
    text: "27words in Telegram mini App",
  },
  {
    id: 6,
    period: "March, 2025",
    text: "Updated mobile version for iOS and Android",
  },
];

export const PARTNERS = [
  {
    id: 1,
    image: IvanLogo,
    name: "Ivan Makhin",
    location: "France, Bordeaux",
    skills: ["Video production", "Colorist"],
    socialIcon: instIcon,
    link: "https://www.instagram.com/makhin.color/",
  },
  {
    id: 1,
    image: FileMarketLogoIcon,
    name: "FileMarket",
    location: "Hong Kong, Hong Kong SAR",
    skills: [
      "P2P file-sharing platform for trading digital goods in web3.",
    ],
    socialIcon: bulatSocialIcon,
    link: "https://filemarket.xyz/",
  },
];

export const MAIL_LINK = "";

export const FOOTER_CONTACTS = [
  {
    id: 1,
    field: "Email",
    link: "mailto:office@ubc.one",
    value: "office@ubc.one",
  },
  {
    id: 2,
    field: "Сrypto Wallet (USDT/USDC ERC-20)",
    link: null,
    value: "0xCcd9B93f0E2C01f27d4aa9EDFafe916E32FF18FC",
  },
  {
    id: 3,
    field: "Сrypto Wallet (USDT TRC-20)",
    link: null,
    value: "TRRmVXbRxk6soMRNkzZvwABv3fPVhBsZwB",
  },
  {
    id: 4,
    field: "PayPal Account",
    link: "https://www.paypal.com/paypalme/ubcoffice",
    value: "@ubcoffice",
  },
];
