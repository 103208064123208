const FileMarketLogoIcon = () => {
  return (
    <svg
      viewBox="0 0 164 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3885 0C22.6406 0 24.4662 1.79086 24.4662 4V18L12.2331 30H4.0777C1.82565 30 0 28.2091 0 26V4C0 1.79086 1.82565 0 4.0777 0H20.3885ZM12.2331 26V18L20.3885 18V4L4.0777 4V26H12.2331Z"
        fill="#0090FF"
      />
      <path
        d="M32.1119 23.3368V12.4945C32.1119 11.6076 32.3305 10.8168 32.7678 10.1221C33.205 9.4126 33.8006 8.8509 34.5545 8.43702C35.3235 8.02314 36.1829 7.8162 37.1328 7.8162H43.2167V11.2529H37.7661C37.3891 11.2529 37.0725 11.3785 36.8162 11.6298C36.5598 11.8811 36.4317 12.1841 36.4317 12.5389V14.5122H42.4929V17.8824H36.4317V23.3368H32.1119Z"
        fill="#0090FF"
      />
      <path
        d="M46.0545 23.3368V11.9181H49.9898V23.3368H46.0545ZM48.0222 10.6764C47.4191 10.6764 46.8989 10.4621 46.4616 10.0334C46.0394 9.60476 45.8284 9.10219 45.8284 8.52571C45.8284 7.93445 46.0394 7.43188 46.4616 7.01799C46.8989 6.58933 47.4191 6.375 48.0222 6.375C48.6253 6.375 49.1379 6.58933 49.5601 7.01799C49.9974 7.43188 50.216 7.93445 50.216 8.52571C50.216 9.10219 49.9974 9.60476 49.5601 10.0334C49.1379 10.4621 48.6253 10.6764 48.0222 10.6764Z"
        fill="#0090FF"
      />
      <path
        d="M53.3917 23.3368V7.15103H57.3044V23.3368H53.3917Z"
        fill="#0090FF"
      />
      <path
        d="M67.0389 23.3368C65.7271 23.3368 64.5661 23.1076 63.5559 22.6494C62.5608 22.1912 61.7692 21.5334 61.1812 20.6761C60.6082 19.8188 60.3217 18.8062 60.3217 17.6385C60.3217 16.3377 60.6007 15.2439 61.1586 14.357C61.7164 13.4701 62.4552 12.7976 63.375 12.3393C64.2947 11.8811 65.2823 11.652 66.3378 11.652C67.4837 11.652 68.4713 11.8885 69.3006 12.3615C70.1449 12.8197 70.7933 13.4701 71.2456 14.3127C71.713 15.1552 71.9467 16.1382 71.9467 17.2616C71.9467 17.4981 71.9316 17.7715 71.9015 18.0819C71.8864 18.3776 71.8487 18.6289 71.7884 18.8358H64.3927C64.4832 19.161 64.6264 19.4344 64.8225 19.6562C65.0335 19.8631 65.3049 20.0183 65.6367 20.1218C65.9834 20.2253 66.3755 20.277 66.8127 20.277H70.6576V23.3368H67.0389ZM64.257 16.5964H68.2376C68.2376 16.3747 68.2074 16.1751 68.1471 15.9978C68.0868 15.8056 68.0039 15.6282 67.8983 15.4656C67.7928 15.303 67.6646 15.1626 67.5138 15.0443C67.3631 14.9261 67.1821 14.8374 66.971 14.7783C66.7599 14.7192 66.5338 14.6896 66.2925 14.6896C65.9759 14.6896 65.697 14.7413 65.4557 14.8448C65.2145 14.9483 65.0109 15.0887 64.8451 15.2661C64.6792 15.4287 64.5435 15.6282 64.438 15.8647C64.3475 16.0864 64.2872 16.3303 64.257 16.5964Z"
        fill="#0090FF"
      />
      <path
        d="M86.5604 23.6028C85.6256 23.6028 84.7511 23.4107 83.9369 23.0263C83.1378 22.642 82.4894 22.0803 81.9919 21.3413C81.5094 20.6022 81.2681 19.7227 81.2681 18.7028V12.3172C81.2681 12.0954 81.2153 11.9033 81.1098 11.7407C81.0193 11.5633 80.8912 11.4303 80.7253 11.3416C80.5595 11.2529 80.371 11.2085 80.1599 11.2085C79.9488 11.2085 79.7603 11.2529 79.5945 11.3416C79.4286 11.4303 79.2929 11.5633 79.1874 11.7407C79.0818 11.9033 79.0291 12.0954 79.0291 12.3172V23.3368H74.845V12.4724C74.845 11.4672 75.0862 10.5951 75.5687 9.85604C76.0512 9.11697 76.692 8.54788 77.4911 8.14878C78.3053 7.7349 79.1949 7.52796 80.1599 7.52796C81.1249 7.52796 82.0069 7.7349 82.8061 8.14878C83.6052 8.54788 84.246 9.11697 84.7285 9.85604C85.211 10.5951 85.4522 11.4672 85.4522 12.4724V18.858C85.4522 19.0649 85.505 19.2571 85.6105 19.4344C85.7161 19.597 85.8518 19.7301 86.0176 19.8335C86.1835 19.9222 86.3644 19.9666 86.5604 19.9666C86.7564 19.9666 86.9374 19.9222 87.1032 19.8335C87.2841 19.7301 87.4274 19.597 87.5329 19.4344C87.6385 19.2571 87.6912 19.0649 87.6912 18.858V12.4724C87.6912 11.4672 87.9325 10.5951 88.415 9.85604C88.8975 9.11697 89.5383 8.54788 90.3374 8.14878C91.1516 7.7349 92.0412 7.52796 93.0062 7.52796C93.9561 7.52796 94.8306 7.7349 95.6297 8.14878C96.4288 8.54788 97.0696 9.11697 97.5521 9.85604C98.0346 10.5951 98.2758 11.4672 98.2758 12.4724V23.3368H94.1144V12.2728C94.1144 12.0659 94.0616 11.8811 93.9561 11.7185C93.8505 11.5559 93.7148 11.4303 93.549 11.3416C93.3831 11.2529 93.2022 11.2085 93.0062 11.2085C92.7951 11.2085 92.5991 11.2529 92.4181 11.3416C92.2523 11.4303 92.1166 11.5559 92.011 11.7185C91.9206 11.8811 91.8753 12.0659 91.8753 12.2728V18.7028C91.8753 19.7227 91.6265 20.6022 91.129 21.3413C90.6314 22.0803 89.9831 22.642 89.1839 23.0263C88.3848 23.4107 87.5103 23.6028 86.5604 23.6028Z"
        fill="#0090FF"
      />
      <path
        d="M106.393 23.625C105.382 23.625 104.485 23.3737 103.701 22.8711C102.917 22.3686 102.299 21.6812 101.847 20.8091C101.409 19.9222 101.191 18.9171 101.191 17.7937C101.191 16.6112 101.455 15.5543 101.982 14.6231C102.525 13.6918 103.279 12.9675 104.244 12.4502C105.209 11.9181 106.347 11.652 107.659 11.652C108.941 11.652 110.041 11.9107 110.961 12.428C111.896 12.9454 112.62 13.6549 113.132 14.5566C113.645 15.4582 113.901 16.4929 113.901 17.6607V23.3368H110.124V21.563H110.034C109.823 21.9621 109.551 22.3168 109.22 22.6272C108.888 22.9377 108.488 23.1816 108.021 23.3589C107.569 23.5363 107.026 23.625 106.393 23.625ZM107.569 20.277C108.051 20.277 108.466 20.1661 108.813 19.9444C109.159 19.7079 109.431 19.3901 109.627 18.991C109.823 18.5919 109.921 18.1411 109.921 17.6385C109.921 17.1359 109.823 16.6999 109.627 16.3303C109.431 15.946 109.159 15.643 108.813 15.4213C108.466 15.1848 108.051 15.0665 107.569 15.0665C107.101 15.0665 106.687 15.1848 106.325 15.4213C105.978 15.643 105.707 15.946 105.511 16.3303C105.314 16.6999 105.216 17.1359 105.216 17.6385C105.216 18.1411 105.314 18.5919 105.511 18.991C105.707 19.3901 105.978 19.7079 106.325 19.9444C106.687 20.1661 107.101 20.277 107.569 20.277Z"
        fill="#0090FF"
      />
      <path
        d="M117.238 23.3368V15.3548C117.238 14.2905 117.547 13.4553 118.165 12.8493C118.799 12.2285 119.673 11.9181 120.789 11.9181H124.362V14.9335H121.987C121.746 14.9335 121.55 15.0074 121.399 15.1552C121.249 15.303 121.173 15.4952 121.173 15.7317V23.3368H117.238Z"
        fill="#0090FF"
      />
      <path
        d="M126.918 23.3368V7.15103H130.831V16.1751H132.097L134.608 11.9181H139.018L135.965 16.5964C136.809 16.9512 137.457 17.4759 137.91 18.1706C138.362 18.8506 138.588 19.7005 138.588 20.7204V23.3368H134.675V20.7204C134.675 20.3509 134.585 20.0257 134.404 19.7449C134.238 19.464 134.004 19.2423 133.703 19.0797C133.416 18.9171 133.092 18.8358 132.73 18.8358H130.831V23.3368H126.918Z"
        fill="#0090FF"
      />
      <path
        d="M148.075 23.3368C146.763 23.3368 145.602 23.1076 144.592 22.6494C143.596 22.1912 142.805 21.5334 142.217 20.6761C141.644 19.8188 141.357 18.8062 141.357 17.6385C141.357 16.3377 141.636 15.2439 142.194 14.357C142.752 13.4701 143.491 12.7976 144.411 12.3393C145.33 11.8811 146.318 11.652 147.373 11.652C148.519 11.652 149.507 11.8885 150.336 12.3615C151.181 12.8197 151.829 13.4701 152.281 14.3127C152.749 15.1552 152.982 16.1382 152.982 17.2616C152.982 17.4981 152.967 17.7715 152.937 18.0819C152.922 18.3776 152.884 18.6289 152.824 18.8358H145.428C145.519 19.161 145.662 19.4344 145.858 19.6562C146.069 19.8631 146.341 20.0183 146.672 20.1218C147.019 20.2253 147.411 20.277 147.848 20.277H151.693V23.3368H148.075ZM145.293 16.5964H149.273C149.273 16.3747 149.243 16.1751 149.183 15.9978C149.122 15.8056 149.04 15.6282 148.934 15.4656C148.828 15.303 148.7 15.1626 148.549 15.0443C148.399 14.9261 148.218 14.8374 148.007 14.7783C147.796 14.7192 147.569 14.6896 147.328 14.6896C147.012 14.6896 146.733 14.7413 146.491 14.8448C146.25 14.9483 146.047 15.0887 145.881 15.2661C145.715 15.4287 145.579 15.6282 145.474 15.8647C145.383 16.0864 145.323 16.3303 145.293 16.5964Z"
        fill="#0090FF"
      />
      <path
        d="M160.472 23.3368C159.371 23.3368 158.504 23.0337 157.871 22.4277C157.253 21.8069 156.944 20.9643 156.944 19.9001V9.19087H160.856V19.6118C160.856 19.7744 160.917 19.9148 161.037 20.0331C161.158 20.1366 161.301 20.1883 161.467 20.1883H164V23.3368H160.472ZM155.112 14.9335V11.9181H164V14.9335H155.112Z"
        fill="#0090FF"
      />
    </svg>
  );
};

export default FileMarketLogoIcon;
