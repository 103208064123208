import React from "react";

const InstagramIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_122_118)">
        <path
          d="M16.8503 0H7.14973C3.20735 0 0 3.20735 0 7.14973V16.8503C0 20.7926 3.20735 24 7.14973 24H16.8503C20.7926 24 24 20.7926 24 16.8503V7.14973C24 3.20735 20.7926 0 16.8503 0ZM21.5856 16.8503C21.5856 19.4655 19.4655 21.5856 16.8503 21.5856H7.14973C4.5345 21.5856 2.4144 19.4655 2.4144 16.8503V7.14973C2.4144 4.53446 4.5345 2.4144 7.14973 2.4144H16.8503C19.4655 2.4144 21.5856 4.53446 21.5856 7.14973V16.8503Z"
          fill="url(#paint0_linear_122_118)"
        />
        <path
          d="M12 5.79275C8.57729 5.79275 5.79272 8.57732 5.79272 12C5.79272 15.4226 8.57729 18.2072 12 18.2072C15.4227 18.2072 18.2072 15.4226 18.2072 12C18.2072 8.57727 15.4227 5.79275 12 5.79275ZM12 15.7929C9.90523 15.7929 8.20712 14.0948 8.20712 12C8.20712 9.90525 9.90527 8.20715 12 8.20715C14.0947 8.20715 15.7928 9.90525 15.7928 12C15.7928 14.0947 14.0947 15.7929 12 15.7929Z"
          fill="url(#paint1_linear_122_118)"
        />
        <path
          d="M18.2198 7.32682C19.0413 7.32682 19.7072 6.6609 19.7072 5.83944C19.7072 5.01798 19.0413 4.35205 18.2198 4.35205C17.3983 4.35205 16.7324 5.01798 16.7324 5.83944C16.7324 6.6609 17.3983 7.32682 18.2198 7.32682Z"
          fill="url(#paint2_linear_122_118)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_122_118"
          x1="12"
          y1="23.9301"
          x2="12"
          y2="0.186412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E09B3D" />
          <stop offset="0.3" stop-color="#C74C4D" />
          <stop offset="0.6" stop-color="#C21975" />
          <stop offset="1" stop-color="#7024C4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_122_118"
          x1="12"
          y1="23.9301"
          x2="12"
          y2="0.186415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E09B3D" />
          <stop offset="0.3" stop-color="#C74C4D" />
          <stop offset="0.6" stop-color="#C21975" />
          <stop offset="1" stop-color="#7024C4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_122_118"
          x1="18.2198"
          y1="23.9302"
          x2="18.2198"
          y2="0.186501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E09B3D" />
          <stop offset="0.3" stop-color="#C74C4D" />
          <stop offset="0.6" stop-color="#C21975" />
          <stop offset="1" stop-color="#7024C4" />
        </linearGradient>
        <clipPath id="clip0_122_118">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramIcon;
