import React from "react";

const NetworkingIcon = () => {
  return (
    <svg
      width="105"
      height="105"
      viewBox="0 0 105 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="104"
        height="104"
        rx="9.5"
        stroke="url(#paint0_linear_303_29)"
      />
      <path
        d="M42.4677 44.4949H63.5333C64.5177 44.4949 65.3182 43.6938 65.3182 42.7101V30.4795C65.3182 29.4961 64.5181 28.696 63.5333 28.696H42.4677C41.4819 28.696 40.6829 29.4961 40.6829 30.4795V42.7104C40.6807 43.6959 41.4819 44.4949 42.4677 44.4949Z"
        fill="#383838"
      />
      <path
        d="M39.5874 46.8167H66.4127C67.1307 46.8167 67.7172 45.9966 67.7172 44.991H55.7221V45.6965H50.2846V44.991H38.2861C38.2839 45.9966 38.8705 46.8167 39.5874 46.8167Z"
        fill="#383838"
      />
      <path
        d="M22.1832 67.9155H43.2466C44.2324 67.9155 45.0314 67.1154 45.0314 66.1328V53.9C45.0314 52.9152 44.2302 52.114 43.2466 52.114H22.1832C21.1977 52.114 20.3987 52.9152 20.3987 53.9V66.1331C20.3987 67.1154 21.1999 67.9155 22.1832 67.9155Z"
        fill="#383838"
      />
      <path
        d="M47.4348 68.4089H35.4381V69.1156H29.9985V68.4089H18C18 69.4134 18.5866 70.2336 19.3034 70.2336H46.1277C46.8467 70.2358 47.4348 69.4156 47.4348 68.4089Z"
        fill="#383838"
      />
      <path
        d="M60.967 53.9V66.1331C60.967 67.1176 61.7671 67.9158 62.7519 67.9158H83.8153C84.8019 67.9158 85.6002 67.1157 85.6002 66.1331V53.9C85.6002 52.9152 84.7979 52.114 83.8153 52.114H62.7519C61.7675 52.114 60.967 52.9152 60.967 53.9Z"
        fill="#383838"
      />
      <path
        d="M76.0043 68.4089V69.1156H70.5635V68.4089H58.5662C58.5662 69.4134 59.1527 70.2336 59.8685 70.2336H86.6949C87.4129 70.2336 87.9995 69.4134 87.9995 68.4089H76.0043Z"
        fill="#383838"
      />
      <path
        d="M58.0245 73.7676H53.6637V52.114H52.3366V73.7676H47.9762V74.8721H33.126V76.1967H47.9762V77.3038H58.0245V76.1967H72.8754V74.8721H58.0245V73.7676Z"
        fill="#383838"
      />
      <defs>
        <linearGradient
          id="paint0_linear_303_29"
          x1="0"
          y1="0"
          x2="92.9038"
          y2="-1.9277e-08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7DC5" />
          <stop offset="1" stop-color="#1E5F6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NetworkingIcon;
