import React, { useState } from "react";
import ScrollMobileIcon from "../../assets/icons/ScrollMobileIcon";
import mouse from "../../assets/images/mouse.png";
import { MENU_LINKS } from "../../constants";
import mobileBg from "../../assets/images/home-bg__mobile.svg";
import "./Home.scss";

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const executeScroll = (block) => {
    let anchor = document.querySelector(`${block}`);
    anchor?.scrollIntoView({ behavior: "smooth" });
    setIsMenuOpen(false);
  };

  return (
    <section className="home container">
      <img src={mobileBg} alt="" className="home-bg__mobile" />
      <div className="home-burger" onClick={() => setIsMenuOpen(true)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="home-content">
        <h1 className="home-content__title shadow">
          United Business Collaboration <br />{" "}
          <span className="text-gradient">start-up’s </span>project office
        </h1>
        <p className="home-content__text">
          The main purpose of a project office is <br /> to achieve the maximum
          economic <br /> effect from the activities of the <br /> members of
          the association <br />
        </p>
        <div className="home-content__mouse">
          <img src={mouse} alt="mouse-scroll" />
          <ScrollMobileIcon />
          <p>Scroll to explore</p>
        </div>
      </div>
      <nav className={`${isMenuOpen ? "nav-open" : ""} home-nav`}>
        <img src={mobileBg} alt="" className="home-bg__mobile" />
        <div className="home-nav__close" onClick={() => setIsMenuOpen(false)}>
          <span></span>
          <span></span>
        </div>
        <div className="home-nav__title shadow">
          United Business <br /> Collaboration
          <p>
            <span className="text-gradient ">start-up’s </span>project office
          </p>
        </div>
        <div className="home-nav__text">
          The main purpose of a project office is to achieve the maximum
          economic effect from the activities of the members of the association
        </div>
        <ul className="nav-links">
          {MENU_LINKS.map((link) => (
            <li className={`nav-link ${isMenuOpen && "nav-link-open"}`}>
              <span onClick={() => executeScroll(link.id)}>{link.text}</span>
            </li>
          ))}
        </ul>
      </nav>
    </section>
  );
};

export default Home;
