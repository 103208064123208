import React from "react";
import BrowserIcon from "../../assets/icons/BrowserIcon";
import appStore from "../../assets/images/appStore.webp";
import iPhone from "../../assets/images/iphone.png";
import "./CurrentProject.scss";
import mobileBg from "../../assets/images/bg-currentProject.png";
import { CURRENT_PROJECT } from "../../constants";
import TelegramIcon from "../../assets/icons/TelegramIcon";
import googlePlay from "../../assets/images/google-play.webp"

const CurrentProject = () => {
  return (
    <section id="currentProject" className="project container">
      <img src={mobileBg} alt="" className="project-bg__mobile" />
      <h3 className="project-title_mobile">Current project</h3>
      <div className="project-visual">
        <img src={iPhone} alt="" />
      </div>
      <div className="project-info">
        <h3 className="project-title">Current project</h3>
        <div className="project-text">
          <p>
            27words is a handy app for saving, repeating and <br />{" "}
            memorizing words. {" "}
            <br /> Expand your vocabulary by adding new words 
            <br /> or use the Cuppic Ai-Assistant to learn more.
            <br /> Good luck in learning foreign languages with AI!
          </p>
        </div>
        <div className="project-social">
          <a
            href={CURRENT_PROJECT.browserLink}
            alt="browser"
            target="_blank"
            rel="noreferrer"
          >
            <BrowserIcon />
          </a>
          <a
            href={CURRENT_PROJECT.telegramLink}
            alt="telegram"
            target="_blank"
            rel="noreferrer"
          >
            <TelegramIcon />
          </a>
        </div>
        <a
          className="project-market"
          href={CURRENT_PROJECT.appStoreLink}
          target="_blank"
          rel="noreferrer"
        >
          <img src={appStore} alt="app-store" />
        </a>
        <a
          className="project-market"
          href={CURRENT_PROJECT.googlePlayLink}
          target="_blank"
          rel="noreferrer"
        >
          <img src={googlePlay} alt="google-play" />
        </a>
      </div>
      <div className="project-info_mobile">
        <div className="mobile-text">
          27words is a handy app for saving, repeating and memorizing words.
          Expand your vocabulary by adding new words 
          or use the Cuppic Ai-Assistant to learn more.
          <br /> Good luck in learning foreign languages with AI!
        </div>
        <div className="mobile-social">
          <a
            href={CURRENT_PROJECT.browserLink}
            target="_blank"
            rel="noreferrer"
          >
            <BrowserIcon />
          </a>
          <a
            href={CURRENT_PROJECT.telegramLink}
            alt="telegram"
            target="_blank"
            rel="noreferrer"
          >
            <TelegramIcon />
          </a>
        </div>
        <a
          className="mobile-market"
          href={CURRENT_PROJECT.appStoreLink}
          target="_blank"
          rel="noreferrer"
        >
          <img src={appStore} alt="app-store" />
        </a>
        <a
          className="mobile-market"
          href={CURRENT_PROJECT.googlePlayLink}
          target="_blank"
          rel="noreferrer"
        >
          <img src={googlePlay} alt="google-play" />
        </a>
      </div>
    </section>
  );
};

export default CurrentProject;
