import React from "react";

const BrowserIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_143_216)">
        <path
          d="M16.1683 7.7013C15.7224 7.2689 15.2099 6.90474 14.6475 6.62634C14.9191 7.03906 15.1581 7.51666 15.3568 8.0493C15.6422 7.94698 15.9134 7.83058 16.1683 7.7013Z"
          fill="#2A4FD3"
        />
        <path
          d="M9.0083 11.5851H11.5849V9.40188C10.8149 9.36844 10.0704 9.25996 9.37678 9.08276C9.1691 9.84132 9.03902 10.6908 9.0083 11.5851Z"
          fill="#2A4FD3"
        />
        <path
          d="M9.32622 14.7162C10.0352 14.5303 10.7977 14.4156 11.5847 14.3807V12.4149H9.0083C9.03702 13.2312 9.14862 14.0096 9.32622 14.7162Z"
          fill="#2A4FD3"
        />
        <path
          d="M9.63281 8.29333C10.2447 8.44565 10.9024 8.53965 11.5847 8.57093V6.02405C11.4645 6.03237 11.3456 6.04421 11.2275 6.05957C10.5898 6.44629 10.0312 7.24445 9.63281 8.29333Z"
          fill="#2A4FD3"
        />
        <path
          d="M7.75391 16.2232C8.28879 16.7608 8.92479 17.1971 9.63039 17.5023C9.28863 17.0147 8.99527 16.4259 8.76311 15.7584C8.40087 15.892 8.06271 16.0475 7.75391 16.2232Z"
          fill="#2A4FD3"
        />
        <path
          d="M9.62288 6.50085C8.98136 6.77933 8.39832 7.16685 7.896 7.63997C8.18504 7.79493 8.49768 7.93109 8.82848 8.04997C9.04768 7.46213 9.316 6.94093 9.62288 6.50085Z"
          fill="#2A4FD3"
        />
        <path
          d="M8.57561 8.83826C8.12457 8.6781 7.70313 8.48659 7.31905 8.26562C6.58057 9.18939 6.11001 10.3349 6.02393 11.585H8.17705C8.20753 10.5963 8.34745 9.66882 8.57561 8.83826Z"
          fill="#2A4FD3"
        />
        <path
          d="M8.17721 12.4149H6.02393C6.10529 13.5982 6.53017 14.6888 7.20161 15.5851C7.60121 15.345 8.04569 15.1363 8.52497 14.9632C8.32745 14.1844 8.20585 13.3258 8.17721 12.4149Z"
          fill="#2A4FD3"
        />
        <path
          d="M11.243 17.9427C11.356 17.9568 11.47 17.9682 11.5849 17.9761V15.2118C10.8784 15.245 10.1962 15.3463 9.56396 15.5093C9.96844 16.66 10.5608 17.5366 11.243 17.9427Z"
          fill="#2A4FD3"
        />
        <path
          d="M15.6611 14.96C16.0879 15.1145 16.4882 15.2969 16.8542 15.5069C17.4926 14.6255 17.8968 13.5646 17.9759 12.4152H16.0083C15.9797 13.3245 15.8585 14.1821 15.6611 14.96Z"
          fill="#2A4FD3"
        />
        <path
          d="M14.6406 17.3771C15.2662 17.0685 15.8297 16.6539 16.3093 16.1571C16.0341 16.0091 15.7368 15.8765 15.4217 15.7607C15.2089 16.3709 14.9454 16.9156 14.6406 17.3771Z"
          fill="#2A4FD3"
        />
        <path
          d="M12 0C5.37289 0 0.000488281 5.37288 0.000488281 11.9998C0.000488281 18.6266 5.37289 24 12 24C18.6271 24 24 18.627 24 11.9998C24 5.37256 18.6272 0 12 0ZM12.0002 19.237C8.00985 19.237 4.76329 15.9906 4.76329 12C4.76329 8.00936 8.00985 4.76304 12.0002 4.76304C15.9906 4.76304 19.2372 8.00936 19.2372 12C19.2372 15.9906 15.9906 19.237 12.0002 19.237Z"
          fill="#2A4FD3"
        />
        <path
          d="M13.0173 6.09617C12.8194 6.06217 12.6183 6.03809 12.4146 6.02393V8.57889C13.1613 8.55689 13.8827 8.45793 14.5514 8.29113C14.1657 7.27617 13.6298 6.49561 13.0173 6.09617Z"
          fill="#2A4FD3"
        />
        <path
          d="M15.6099 8.83764C15.8383 9.6682 15.9782 10.5961 16.0087 11.585H17.976C17.8924 10.3668 17.4434 9.24828 16.7373 8.3374C16.3885 8.52764 16.0113 8.69532 15.6099 8.83764Z"
          fill="#2A4FD3"
        />
        <path
          d="M12.4146 17.976C12.6138 17.9625 12.8105 17.9389 13.004 17.9063C13.6601 17.4854 14.2286 16.6262 14.621 15.5105C13.9319 15.3327 13.1846 15.2271 12.4146 15.2039V17.976Z"
          fill="#2A4FD3"
        />
        <path
          d="M14.8079 9.08069C14.0572 9.27285 13.2485 9.38573 12.4146 9.40893V11.5851H15.1773C15.1463 10.6902 15.016 9.83989 14.8079 9.08069Z"
          fill="#2A4FD3"
        />
        <path
          d="M15.177 12.4149H12.4146V14.3738C13.2731 14.3968 14.0987 14.5116 14.8604 14.7104C15.0374 14.0056 15.1484 13.2291 15.177 12.4149Z"
          fill="#2A4FD3"
        />
      </g>
      <defs>
        <clipPath id="clip0_143_216">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BrowserIcon;
