import React from "react";

const ImproveIcon = () => {
  return (
    <svg
      width="105"
      height="105"
      viewBox="0 0 105 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="104"
        height="104"
        rx="9.5"
        stroke="url(#paint0_linear_303_31)"
      />
      <path
        d="M80.1565 52.4501H25.8444C25.3553 52.4501 24.9504 52.8565 24.9504 53.3476V76.8525C24.9504 77.3436 25.3553 77.7501 25.8444 77.7501H80.1565C80.6456 77.7501 81.0504 77.3436 81.0504 76.8525V53.3476C81.0504 52.8565 80.6456 52.4501 80.1565 52.4501Z"
        fill="#383838"
      />
      <path
        d="M31.277 54.9448H27.7012V58.5207H31.277V54.9448Z"
        fill="#FAFAFA"
      />
      <path
        d="M37.1464 54.9448H33.5706V58.5207H37.1464V54.9448Z"
        fill="#FAFAFA"
      />
      <path
        d="M43.0324 54.9448H39.4565V58.5207H43.0324V54.9448Z"
        fill="#FAFAFA"
      />
      <path
        d="M48.9018 54.9448H45.3259V58.5207H48.9018V54.9448Z"
        fill="#FAFAFA"
      />
      <path d="M54.79 54.9448H51.2141V58.5207H54.79V54.9448Z" fill="#FAFAFA" />
      <path
        d="M60.6593 54.9448H57.0835V58.5207H60.6593V54.9448Z"
        fill="#FAFAFA"
      />
      <path d="M66.7509 55.2H62.9009V58.5H66.7509V55.2Z" fill="#FAFAFA" />
      <path d="M72.249 55.2H68.949V58.5H72.249V55.2Z" fill="#FAFAFA" />
      <path d="M78.3004 55.2H74.4504V58.5H78.3004V55.2Z" fill="#FAFAFA" />
      <path
        d="M31.277 60.4266H27.7012V64.0025H31.277V60.4266Z"
        fill="#FAFAFA"
      />
      <path
        d="M37.1464 60.4265H33.5706V64.0024H37.1464V60.4265Z"
        fill="#FAFAFA"
      />
      <path
        d="M43.0324 60.4265H39.4565V64.0024H43.0324V60.4265Z"
        fill="#FAFAFA"
      />
      <path
        d="M48.9018 60.4265H45.3259V64.0024H48.9018V60.4265Z"
        fill="#FAFAFA"
      />
      <path d="M54.79 60.4265H51.2141V64.0024H54.79V60.4265Z" fill="#FAFAFA" />
      <path
        d="M60.6593 60.4265H57.0835V64.0024H60.6593V60.4265Z"
        fill="#FAFAFA"
      />
      <path
        d="M66.5473 60.4265H62.9714V64.0024H66.5473V60.4265Z"
        fill="#FAFAFA"
      />
      <path
        d="M72.4167 60.4265H68.8408V64.0024H72.4167V60.4265Z"
        fill="#FAFAFA"
      />
      <path
        d="M78.3029 60.4265H74.7271V64.0024H78.3029V60.4265Z"
        fill="#FAFAFA"
      />
      <path
        d="M31.277 65.9253H27.7012V69.5011H31.277V65.9253Z"
        fill="#FAFAFA"
      />
      <path
        d="M37.1464 65.9253H33.5706V69.5011H37.1464V65.9253Z"
        fill="#FAFAFA"
      />
      <path
        d="M43.0324 65.9253H39.4565V69.5011H43.0324V65.9253Z"
        fill="#FAFAFA"
      />
      <path
        d="M48.9018 65.9253H45.3259V69.5011H48.9018V65.9253Z"
        fill="#FAFAFA"
      />
      <path d="M54.79 65.9253H51.2141V69.5011H54.79V65.9253Z" fill="#FAFAFA" />
      <path
        d="M60.6593 65.9253H57.0835V69.5011H60.6593V65.9253Z"
        fill="#FAFAFA"
      />
      <path
        d="M66.5473 65.9253H62.9714V69.5011H66.5473V65.9253Z"
        fill="#FAFAFA"
      />
      <path
        d="M72.4167 65.9253H68.8408V69.5011H72.4167V65.9253Z"
        fill="#FAFAFA"
      />
      <path d="M31.277 71.407H27.7012V74.9828H31.277V71.407Z" fill="#FAFAFA" />
      <path
        d="M37.1464 71.407H33.5706V74.9828H37.1464V71.407Z"
        fill="#FAFAFA"
      />
      <path
        d="M48.9018 71.407H45.3259V74.9828H48.9018V71.407Z"
        fill="#FAFAFA"
      />
      <path
        d="M54.7888 71.407H39.4565V74.9828H54.7888V71.407Z"
        fill="#FAFAFA"
      />
      <path
        d="M60.6593 71.407H57.0835V74.9828H60.6593V71.407Z"
        fill="#FAFAFA"
      />
      <path
        d="M66.5473 71.407H62.9714V74.9828H66.5473V71.407Z"
        fill="#FAFAFA"
      />
      <path
        d="M72.4167 71.407H68.8408V74.9828H72.4167V71.407Z"
        fill="#FAFAFA"
      />
      <path d="M78.3004 66.2H74.4504V75H78.3004V66.2Z" fill="#FAFAFA" />
      <path
        d="M53.1435 53.0001C52.6886 53.0001 52.3179 52.6293 52.3179 52.1742V48.1794C52.3179 45.3477 54.6261 43.0385 57.4567 43.0385H67.1445C69.0652 43.0385 70.6153 41.471 70.6153 39.5663C70.6153 37.6448 69.0484 36.0941 67.1445 36.0941H44.9383C42.1078 36.0941 39.7996 33.7849 39.7996 30.9532V28.526C39.7996 28.0709 40.1702 27.7001 40.6251 27.7001C41.08 27.7001 41.4507 28.0709 41.4507 28.526V30.9363C41.4507 32.8578 43.0176 34.4085 44.9215 34.4085H67.1108C69.9413 34.4085 72.2496 36.7177 72.2496 39.5494C72.2496 42.3812 69.9413 44.6903 67.1108 44.6903H57.423C55.5023 44.6903 53.9522 46.2579 53.9522 48.1626V52.1573C53.9691 52.6293 53.5984 53.0001 53.1435 53.0001Z"
        fill="#383838"
      />
      <defs>
        <linearGradient
          id="paint0_linear_303_31"
          x1="0"
          y1="0"
          x2="92.9038"
          y2="-1.9277e-08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7A7DC5" />
          <stop offset="1" stop-color="#1E5F6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ImproveIcon;
