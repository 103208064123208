import React from "react";
import {
  PROJECTS_COLUMN_ONE,
  PROJECTS_COLUMN_SECOND,
  PROJECTS_MOBILE,
} from "../../constants";
import Card from "../Card/card.component";
import bgBottom from "../../assets/images/bg-bottom.png";
import bgMedium from "../../assets/images/bg-medium.png";
import "./projects.scss";

const Projects = () => {
  return (
    <section id="projects" className="projects-wrapper">
      <img className="projects-bg__medium" src={bgMedium} />
      <div className="container">
        <h2 className="objectives-title title">UBC Projects:</h2>
        <div className="projects-items">
          <div className="projects__first-column">
            {PROJECTS_COLUMN_ONE.map((el) => (
              <Card key={el.id} {...el} />
            ))}
          </div>
          <div className="projects__second-column">
            {PROJECTS_COLUMN_SECOND.map((el) => (
              <Card key={el.id} {...el} />
            ))}
          </div>
        </div>
        <div className="projects__mobile">
          {PROJECTS_MOBILE.map((el) => (
            <Card key={el.id} {...el} />
          ))}
        </div>
      </div>
      <img className="projects-bg__bottom" src={bgBottom} />
    </section>
  );
};

export default Projects;
